import axios from 'axios'; // or, you can use fetch. It's up to you :)
// 
const authToken = process.env.REACT_APP_TELEGRAM;
const chatId = process.env.REACT_APP_CHAT;


const sendTelegramNotification = async (message) => {
    const url = `https://api.telegram.org/bot${authToken}/sendMessage`;
    const payload = {
        chat_id: chatId,
        text: message,
        // https://core.telegram.org/bots/api#html-style
        parse_mode: 'html' // html | markdown
    }
    
  return  axios.post(url, payload)
}

export default sendTelegramNotification;