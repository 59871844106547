import office from '../images/deliveryOffice.jpg'
import house from '../images/deliveryHome.jpg'
import OrderButton from './OrderButton';

export default function Options() {
  return (
    <section className="text-gray-600 body-font max-w-screen-xl mx-auto">
      {/* <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-10">
        Вибираючи нас, ви отримуєте:
      </h1> */}
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-wrap -mx-4 -mb-10 text-center gap-3">
          <div className="sm:flex-1 mb-10 px-10 bg-gray-50 py-14 rounded-lg">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-contain object-center h-full w-full"
                src={house}
              />
            </div>
            <h2 className="title-font text-2xl font-medium text-blue-500 mt-6 mb-3">
              Доставка додому
            </h2>
            <p className="leading-relaxed text-base mb-6">
              Освіжаюча вода для вашого дому прямо до дверей. Замовте доставку
              води в Обухові та насолоджуйтесь безтурботністю та смачністю
              кожної краплі. Наша вода - ваше здоров'я.
            </p>
            <OrderButton title={'Замовити'} />
          </div>

          <div className="sm:flex-1 mb-10 px-10 bg-gray-50 py-14 rounded-lg">
            <div className="rounded-lg  h-64  overflow-hidden">
              <img
                alt="content"
                className="object-contain object-center h-full w-full"
                src={office}
              />
            </div>
            <h2 className="title-font text-2xl font-medium text-blue-500 mt-6 mb-3">
              Доставка води в офіс
            </h2>
            <p className="leading-relaxed text-base mb-6">
              Надійна доставка води прямо до вашого офісу. Перетворіть свій офіс
              на зону комфорту та ефективності з чистою артезіанською водою.
              Відчуйте різницю у якості та зручності.
            </p>
            <OrderButton title={'Замовити'} />
          </div>
        </div>
      </div>
    </section>
  )
}
