import './App.css'
import Navbar from './Navbar'
import Pricing from './pages/Pricing'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

function App() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize('G-V9TEHCD2F5')
    ReactGA.pageview(location.pathname)
  }, [location.pathname])

  return (
    <>
      <Navbar />
      <div className="container max-w-screen-xl mx-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  )
}

export default App
