import pompa from '../images/pompa.jpg'
import pompa2 from '../images/pompa2.jpg'
import bottle from '../images/bottle.jpg'
import bottle2 from '../images/bottle2.jpg'
import bottledWater from '../images/BottledWater.png'
import { Footer, Form, OrderButton } from '../components'

const WaterCardDescrption = ({ name, price, body }) => {
  return (
    <section className="text-gray-600 body-font overflow-hidden w-full md:w-1/2 ">
      <div className="container py-10 px-5 h-full">
        <div className="lg:w-5/5 mx-auto flex flex-wrap h-full">
          <div className=" w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0  h-full flex flex-col">
            <div className="flex flex-1 flex-col ">
              <h1 className="text-blue-500 text-3xl title-font font-medium mb-1">
                {name}
              </h1>
              <div className="leading-relaxed mt-4 ">{body}</div>
            </div>

            <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5"></div>
            <div className="flex">
              {price && (
                <span className="title-font font-medium text-2xl text-gray-900">
                  {price}
                </span>
              )}

              <OrderButton
                title={'Перейти до замовлення'}
                className={
                  'flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default function Pricing() {
  const iteLmist = [
    {
      name: 'Помпа електрична',
      image: pompa,
      category: 'Помпи',
      price: 'від 350',
    },
    {
      name: 'Помпа механічна',
      image: pompa2,
      category: 'Помпи',
      price: 'від 200',
    },
    {
      name: 'ПЕТ бутилі (баклажки) ємністю від 1л до 10л',
      image: bottle,
      category: 'Бутель',
      price: 'від 5',
    },
    {
      name: 'Бутиль для води 19л. полікарбонатний',
      image: bottle2,
      category: 'Бутель',
      price: 'від 350',
    },
  ]
  return (
    <div>
      {/* Шапка */}
      <section className="text-gray-600 body-font container max-w-screen-xl mx-auto bg-gray-50 rounded-md">
        <div className="container mx-auto flex px-5 md:pr-0 md:pl-10 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-1">
              ДОСТАВКА ВОДИ
            </h2>
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Ассортимент
            </h1>
            <p className="mb-8 leading-relaxed">
              Надійна доставка питної води додому та в офіс. Висока якість води,
              яка підходить для щоденного вживання та використання в побутовій
              техніці. Різноманітні види води на ваш вибір. Замовте вже сьогодні
              і робіть своє життя простішим!
            </p>
            <div className="flex justify-center">
              <OrderButton title={'Замовити'} />
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={bottledWater}
            />
          </div>
        </div>
      </section>

      <div className="flex flex-wrap">
        <WaterCardDescrption
          name={'Озонована вода'}
          body={
            <div>
              Озонована вода - ваш ключ до здоров'я та енергії. Її унікальні
              властивості включають антиоксидантність, протимікробність та
              поліпшення реакції імунної системи. Вона не має протипоказань та
              підходить для всієї родини. Озонована вода - це секрет життєвого
              відновлення.{' '}
            </div>
          }
          price={'1,5 грн/л'}
        />

        <WaterCardDescrption
          name={'Очищена ПИТНА ВОДА'}
          body={
            <div>
              Очищена ПИТНА ВОДА - ідеально збалансована за складом вода. Не
              залишає накип та зберігає справжній смак ваших страв та напоїв.
              Кращий вибір води для кулінарних та побутових потреб.
            </div>
          }
          price={'1,5 грн/л'}
        />

        <WaterCardDescrption
          name={'Вода кремній-шунгітова'}
          body={
            <div>
              "Питна вода Кремнієва" - це вода, яка має надзвичайно корисні
              властивості для організму. Кремнієва вода володіє цілющими
              властивостями і використовується для очищення і лікування. Вона
              допомагає позбавитися від шлаків і підтримує здоров'я організму.
              Кремній у воді сприяє зниженню рівня холестерину і має
              детоксикаційні властивості. Така вода позитивно впливає на
              шлунково-кишковий тракт, тиск, шкіру і імунну систему. Вона
              корисна для печінки, кісткового обміну і жіночого здоров'я. "Питна
              вода Кремнієва" - це природний сорбент і джерело кремнію, який
              сприяє загальному здоров'ю та добробуту.
            </div>
          }
          price={'2 грн/л'}
        />

        <WaterCardDescrption
          name={'Доставка'}
          body={
            <div className="w-full mx-auto overflow-auto pt-3">
              <table className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                      Кількість
                    </th>

                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                      Ціна
                    </th>
                    <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-3">1 балон / 19л</td>
                    <td className="px-4 py-3 text-lg text-gray-900">90 грн</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-3">2 балон / 19л</td>
                    <td className="px-4 py-3 text-lg text-gray-900">80 грн</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-3">3 балон / 19л</td>
                    <td className="px-4 py-3 text-lg text-gray-900">70 грн</td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        />
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {iteLmist.map((item, index) => (
              <div className="lg:w-1/4 md:w-1/2 p-4 w-full" key={index}>
                <div className="block relative h-48 rounded overflow-hidden">
                  <img
                    alt="ecommerce"
                    className="object-contain object-center w-full h-full block"
                    src={item.image}
                  />
                </div>
                <div className="mt-4">
                  <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                    {item.category}
                  </h3>
                  <h2 className="text-gray-900 title-font text-lg font-medium">
                    {item.name}
                  </h2>
                  <p className="mt-1">{item.price} грн.</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Form />
      <Footer />
    </div>
  )
}
