import React from 'react'
import {
  Features,
  Hero,
  Footer,
  Form,
  Reviews,
  Options,
  About,
} from '../components'

export default function Home() {
  return (
    <>
      <Hero />
      <Options />
      <About />
      <Features />
      <Form />
      <Reviews />
      <Footer />
    </>
  )
}
