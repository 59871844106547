import sendTelegramNotification from '../scripts/ordersBot'
import { useState } from 'react'

export default function Form() {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [additional, setAdditional] = useState('')

  const [validateName, setValidateName] = useState(true)
  const [validatePhone, setValidatePhone] = useState(true)
  const [validateAdd, setValidateAdd] = useState(true)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const sendMessage = () => {
    if (name == '') return setValidateName(false)
    setValidateName(true)
    if (phone == '') return setValidatePhone(false)

    setValidatePhone(true)
    const message =
      "Ім'я: <code>" +
      name +
      '</code>\nНомер телефону: <code>' +
      phone +
      '</code>\nПримітка: ' +
      additional
    sendTelegramNotification(message)
      .then(() => {
        setIsSubmitted(true)
      })
      .catch((error) => {
        console.error('Ошибка отправки:', error)
      })
  }

  return (
    <section id="form-section" className="text-gray-600 body-font relative">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-10">
        Оформлення замовлення
      </h1>
      <div className="containe spx-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            className="absolute inset-0 map-style"
            frameBorder="0"
            title="map"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.6148742246833!2d30.627022900000004!3d50.112215199999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4976dc98a50d5%3A0x95ab01b46874832!2z0YPQuy4g0JrQuNC10LLRgdC60LDRjywgMywg0J7QsdGD0YXQvtCyLCDQmtC40LXQstGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCAwODcwMA!5e0!3m2!1sru!2sua!4v1693232952596!5m2!1sru!2sua"></iframe>
          <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md w-full">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                АДРЕСА
              </h2>
              <p className="mt-1">Україна, м.Обухів, вул. Київська 3А</p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                TELEGRAM
              </h2>
              <a href="" className="text-blue-500 leading-relaxed">
                +380 (50) 780-80-76
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                VIBER
              </h2>
              <a href="" className="text-violet-500 leading-relaxed">
                +380 (96) 879-25-36
              </a>
            </div>
          </div>
        </div>
        <div className="px-5 md:px-0 lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          {isSubmitted ? (
            <>
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                Замовити доставку
              </h2>
              <p className="leading-relaxed mb-5 text-gray-600">
                Залиште свої дані для зв'язку з вами
              </p>
              <div className="flex flex-col w-full justify-center items-center mt-10 mb-10">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  className="text-blue-500 w-14 h-14 flex-shrink-0 mr-4"
                  viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
                <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-10">
                  Замовлення прийнято!
                </h1>
                <p>
                  Дякуємо за ваше замовлення доставки води. Наш менеджер
                  зв'яжеться з вами найближчим часом, щоб уточнити деталі та
                  підтвердити доставку.
                </p>
                <p className="mt-6">Бажаємо вам гарного дня!</p>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                Замовити доставку
              </h2>
              <p className="leading-relaxed mb-5 text-gray-600">
                Залиште свої дані для зв'язку з вами
              </p>
              <div className="relative mb-4">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-600">
                  Ім'я
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={(event) => setName(event.target.value)}
                  className={
                    `w-full bg-white rounded border focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out` +
                    (validateName ? ' border-gray-300' : ' border-red-300')
                  }
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="phone"
                  className="leading-7 text-sm text-gray-600">
                  Номер телефону
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={(event) => setPhone(event.target.value)}
                  className={
                    `w-full bg-white rounded border focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out` +
                    (validatePhone ? ' border-gray-300' : ' border-red-300')
                  }
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-600">
                  Примітка
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={(event) => setAdditional(event.target.value)}
                  className={
                    `w-full bg-white rounded border focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out` +
                    (validateAdd ? ' border-gray-300' : ' border-red-300')
                  }
                />{' '}
              </div>
              <button
                className="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
                onClick={sendMessage}>
                Відправити
              </button>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
