import logo from '../logo.svg'
export default function Footer() {
  return (
    <footer className="text-gray-600 body-font ">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a  href='/'  className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img src={logo} alt="Logo" />
            <span className="ml-3 text-xl">ПИТНА ВОДА</span>
          </a>
          <p className="mt-2 text-sm text-gray-500">
          Замовляйте воду в Обухові  від компанії «ПИТНА ВОДА» – зробіть своє життя простіше!
          </p>
        </div>
        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-full md:w-1/2 w-full px-4 flex flex-row-reverse">
            <div className="flex flex-col">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                КОНТАКТИ
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <span className="font-medium">Адреса: </span> 
                  Україна, м.Обухів,
                  вул. Київська 3А
                </li>
                <li>
                  <span className="font-medium">Telegram: </span> 
                  +380 (50) 780-80-76
                </li>
                <li>
                  <span className="font-medium">Viber: </span> 
                  +380 (96) 879-25-36
                </li>
                <li>
                  <span className="font-medium">Замовлення води: </span> 
                  Пон. - Нед. / 8:00 до 20:00
                </li>
                
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2023 ПИТНА ВОДА —
            <a
              href="https://twitter.com/knyttneve"
              rel="noopener noreferrer"
              className="text-gray-600 ml-1"
              target="_blank">
              developed by @veg_it
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
