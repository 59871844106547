export default function Features() {
  return (
    <section className="text-gray-600 body-font container">
      <div className="container px-5 py-24 mx-auto">
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20">
          Обираючи нас, ви отримуєте:
        </h1>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col">
            <div className=" inline-flex items-center justify-center rounded-lg  text-blue-500 mb-4 flex-shrink-0 py-5">
              <h1 className="text-9xl">01</h1>
            </div>
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                Якість
              </h2>
              <p className="leading-relaxed text-base">
                Наші пляшки містять лише якісну та природну питну воду. Ми
                гарантуємо чистоту кожної краплі.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col">
            <div className=" inline-flex items-center justify-center rounded-lg  text-blue-500 mb-4 flex-shrink-0 py-5">
              <h1 className="text-9xl">02</h1>
            </div>
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                Зручність
              </h2>
              <p className="leading-relaxed text-base">
                Наш сервіс доставки води робить життя простішим. Жодної
                необхідності в підйомі важких сулій.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col">
            <div className=" inline-flex items-center justify-center rounded-lg  text-blue-500 mb-4 flex-shrink-0 py-5">
              <h1 className="text-9xl">03</h1>
            </div>
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                Турбота про здоров'я
              </h2>
              <p className="leading-relaxed text-base">
                Питна вода важлива для вашого здоров'я, і ми робимо її доступною
                та зручною для вас.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
