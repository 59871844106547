import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo2 from './logo.svg'
import * as ReactScroll from 'react-scroll'
import { OrderButton } from './components'

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const scrollToTop = () => {
    window.scrollTo(0, 0)
    isNavOpen ? setIsNavOpen(false) : setIsNavOpen(false)
  }
  const scrollToForm = () => {}
  return (
    <header className="text-gray-600 body-font sticky top-0 z-50 bg-white max-w-screen-xl mx-auto">
      <div className={isNavOpen ? 'absolute' : 'hidden'}>
        <div className="bg-white h-screen md:hidden w-screen px-10 py-10">
          <div className="flex justify-between items-center mb-8">
            <Link
              to="/"
              className="flex title-font font-medium items-center text-gray-900 mb-0"
              onClick={scrollToTop}>
              <img src={logo2} alt="Logo"></img>
              <div className="flex flex-col">
                <span className="ml-3 text-xl">ПИТНА ВОДА</span>
                <span className="ml-3 text-xs text-gray-500">
                  Доставку артезіанської води по Обухову
                </span>
              </div>
            </Link>
            <div onClick={() => setIsNavOpen(false)}>
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
          <div className="flex flex-col">
            <Link
              to="/"
              className="mr-5 hover:text-gray-900 mb-5 text-center"
              onClick={scrollToTop}>
              Головна
            </Link>
            <Link
              to="/pricing"
              className="mr-5 hover:text-gray-90 mb-5 text-center"
              onClick={scrollToTop}>
              Ассортимент
            </Link>
            <ReactScroll.Link
              to="form-section"
              onClick={() =>
                isNavOpen ? setIsNavOpen(false) : setIsNavOpen(false)
              }
              smooth={true}
              duration={500}
              className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded mt-4 md:mt-0 mb-5 text-center justify-center">
              Замовити
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-1"
                viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </ReactScroll.Link>
          </div>
        </div>
      </div>

      <div className=" flex justify-between flex-wrap p-5 flex-row items-center">
        <Link
          to="/"
          className="flex title-font font-medium items-center text-gray-900 mb-0"
          onClick={scrollToTop}>
          <img src={logo2} alt="Logo"></img>
          <div className="hidden md:flex flex-col">
            <span className="ml-3 text-xl">ПИТНА ВОДА</span>
            <span className="ml-3 text-xs text-gray-500">
              Доставка артезіанської води по Обухову{' '}
            </span>
          </div>
        </Link>
        <div className={isNavOpen ? 'hidden' : 'block'}>
          <div
            className=" md:hidden space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}>
            <span className="block h-0.5 w-8 animate-pulse bg-blue-500"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-blue-500"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-blue-500"></span>
          </div>
        </div>
        <nav className="hidden md:flex md:ml-auto flex-wrap items-center text-base justify-center">
          <Link
            to="/"
            className="mr-5 hover:text-gray-900"
            onClick={scrollToTop}>
            Головна
          </Link>
          <Link
            to="/pricing"
            className="mr-5 hover:text-gray-900"
            onClick={scrollToTop}>
            Ассортимент
          </Link>
        </nav>

        <ReactScroll.Link
          to="form-section"
          onClick={() =>
            isNavOpen ? setIsNavOpen(false) : setIsNavOpen(false)
          }
          smooth={true}
          duration={500}
          className="hidden md:inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 cursor-pointer">
          Замовити
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4 ml-1"
            viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </ReactScroll.Link>
      </div>
    </header>
  )
}
