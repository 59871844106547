import bottledWater from '../images/BottledWater2.png'
import { Link } from 'react-router-dom'
import OrderButton from './OrderButton';

export default function Hero() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  return (
    <section className="text-gray-600 body-font container max-w-screen-xl mx-auto bg-gray-50 rounded-md">
      <div className="container mx-auto flex px-5 md:pr-0 md:pl-10 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h2 className="text-xs text-blue-500 tracking-widest font-medium title-font mb-1">
            Чиста артезіанська вода в Обухові
          </h2>
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            «ПИТНА ВОДА» -{' '}
            <br className="hidden lg:inline-block" /> Здорова і смачна вода для вашої
            сім'ї та бізнесу
          </h1>
          <p className="mb-8 leading-relaxed">
          Надійна доставка питної води додому та в офіс. Висока якість води, яка підходить для щоденного вживання та використання в побутовій техніці. Різноманітні види води на ваш вибір. Замовте вже сьогодні і робіть своє життя простішим!
          </p>
          <div className="flex justify-center">
            <OrderButton title={'Замовити'} />
            <Link
              to="/pricing"
              className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"
              onClick={scrollToTop}>
              Ассортимент
            </Link>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={bottledWater}
          />
        </div>
      </div>
    </section>
  )
}
