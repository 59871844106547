import man from '../images/revMan.jpg'
import girl from '../images/revGirl.jpg'
import woman from '../images/revWoman.jpg'
export default function Reviews() {
  return (
    <section className="text-gray-600 body-font">
      <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-10">
        Відгуки
      </h1>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={man}
              />
              <p className="leading-relaxed">
                Я вже декілька місяців користуюся послугами компанії "Доставка
                Води" і хочу висловити свою повну задоволеність. Як працюючій
                людині, мені немає часу займатися постійною покупкою і підйомом
                важких пляшок з водою. Сервіс доставки просто додому став для
                мене справжнім врятованим. Вода завжди свіжа і смачна, а
                регулярна доставка забезпечує мені комфорт і турботу про
                здоров'я. Велике спасибі за вашу роботу!
              </p>
              <span className="inline-block h-1 w-10 rounded bg-blue-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                Олександр
              </h2>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={woman}
              />
              <p className="leading-relaxed">
                Я хочу поділитися своїм враженням про компанію "Доставка Води".
                Працюючи в офісі, де дбають про комфорт співробітників, я
                оцінила значення чистої питної води. З вашим сервісом доставки
                води для офісу, наш колектив тепер може завжди насолоджуватися
                свіжою та якісною водою, не виходячи з робочого простору. Це
                дійсно додає догляду за здоров'ям до нашої повсякденності. Дякую
                вам за вашу увагу до деталей і якісне обслуговування!
              </p>
              <span className="inline-block h-1 w-10 rounded bg-blue-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                Оксана
              </h2>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={girl}
              />
              <p className="leading-relaxed">
                Я просто у захваті від компанії "Доставка Води"! Сучасне життя
                настільки насичене справами, що іноді навіть немає часу вибігти
                за пляшкою води. Ваш сервіс став для мене справжнім врятованим.
                Я можу легко та зручно замовити воду через вашу платформу і бути
                впевненою, що вона завжди буде якісною та свіжою. Також це дуже
                зручно для підтримки гідратації протягом дня. Велике спасибі за
                інноваційний підхід та турботу про клієнтів!
              </p>
              <span className="inline-block h-1 w-10 rounded bg-blue-500 mt-6 mb-4"></span>
              <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                Анна
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
