export default function About() {
  return (
    <section className="text-gray-600 body-font container">
      <div className="container px-5 py-24 mx-auto ">
        <h1 className="sm:text-3xl text-2xl font-medium title-font text-center text-gray-900 mb-20">
          Про нас
        </h1>
        <h2 className="font-bold text-blue-400 mb-3">
          Магазин «ПИТНА ВОДА» здійснює доставку по Обухову артезіанської води.
        </h2>
        <p className=" mb-3 text-lg">
          Якість проточної води залежить від двох головних факторів: чистоти
          джерела і справності водопровідної системи. Ми маємо проблеми в обох
          областях. І якщо саму воду фільтрують, додають знезаражувальні
          речовини, то старі іржаві труби міняти ніхто не поспішає. Та й навіть
          з великим бажанням і ресурсами просто неможливо швидко взяти під
          контроль доставку води по системі на всіх етапах. Додайте до цього
          аварії, які залишають без водопостачання цілі райони міста.{' '}
        </p>
        <p className=" mb-3">
          Тому сьогодні доставка питної води додому чи в офіс сприймається не як
          забаганка з міркувань престижу, а як простий логічний вихід із
          ситуації, що склалась. Все частіше ми можемо побачити, як питна
          бутильована вода надається для загального користування в
          адміністративних установах та лікарнях.{' '}
        </p>
        <p className=" mb-3">
          Ми потребуємо не тільки здорової за своїм хімічним складом, але й
          смачної води: для щоденного споживання, приготування напоїв і страв.
          Тому в закладах харчування для миття посуду користуються водопроводом,
          а для приготування їжі роблять замовлення на бутильовану воду.
        </p>
        <p className=" mb-3">
          «ПИТНА ВОДА» має максимальну користь для вашого здоров’я, а також є
          універсальною для домашнього застосування.
        </p>
        <p className=" mb-3">Наведемо аргументи:</p>
        <p className=" mb-3">
          <span className="font-bold text-blue-400">
            Очищена вода з нейтральним мінеральним складом відповідає природним
            джерелам
          </span>
          , а значить, є кращим варіантом втамування спраги. Вона нормалізує
          гідробаланс, включається в метаболічні процеси і запобігає
          зневодненню. Застосовується в кулінарії – для приготування їжі
          оптимально використовувати нейтральну столову воду з гранично низьким
          вмістом натрію, калію та інших мікроелементів. Тільки в цьому випадку
          ви зможете приготувати ідеальний кави, ароматний чай, компот або суп
          для дитини.
        </p>

        <p className=" mb-3">
          <span className="font-bold text-blue-400">
            Підходить для побутової техніки
          </span>{' '}
          – для пилососів, електрочайників і зволожувачів повітря не
          рекомендується використовувати водопровідну воду, яка дає осад.
          Прилади виходять з ладу через осідання різних домішок і солей важких
          металів. Якісна столова вода не дає осаду і має м’якість, яка не
          шкодить механізмам. «ПИТНА ВОДА» – кращий варіант для домашнього
          використання завдяки м’якості, нейтральності та освіжаючому смаку.{' '}
        </p>

        <p className=" mb-3">
          На ваш смак в ми пропонуємо 3 вида очищеної води:{' '}
          <span className="font-bold text-blue-400">
            Кремнієва, озонована та очищена
          </span>
        </p>

        <p className=" mb-3">
          Доставка «ПИТНОЇ ВОДИ» в ОБУХОВІ – зручна послуга, яка позбавить
          необхідності самостійно їздити за покупками та носити важкі ємності.
          Важлива перевага нашого сервісу – можливість точно спланувати ваш час.{' '}
        </p>

        <p className=" mb-3">
          У продажу дистильована технічна вода, таблетована сіль для
          водоочищення, обладнання для розливу бутильованої води: кулери, помпи,
          диспенсери, підставки, аксесуари з доставкою по Обухову та Українці.{' '}
        </p>

        <p className=" mb-3">
          Замовити воду додому в м. Обухів, або ближні селища ви може в зручний
          час по номерам телефонів вказані на сайті, або залишити заявку у
          відповідному полі сайту. Доставляється вода або в цей же день, якщо
          замовлення до обіду, або на наступний день з 15:00 по 20:00 годину.
          Саме час, коли ви вже вдома.
        </p>

        <p className=" mb-3 font-bold text-blue-400">
          Замовляйте воду в Обухові від компанії «ПИТНА ВОДА» – зробіть своє
          життя простіше!
        </p>
      </div>
    </section>
  )
}
