import * as ReactScroll from 'react-scroll'

export default function OrderButton({title, className}) {
  return (
    <ReactScroll.Link
      to="form-section"
      smooth={true}
      duration={500}
      className={ className ? className : "inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg cursor-pointer"}>
      {title}
    </ReactScroll.Link>
  )
}
