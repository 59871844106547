import React from 'react'
import { Footer } from '../components'

function NotFound() {
  return (
    <>
      <div className="flex flex-col h-screen justify-center items-center bg-slate-50">
        <h2 className="text-blue-500 font-bold text-9xl">404</h2>
        <p> Страница не найдена</p>
      </div>
      <Footer />
    </>
  )
}

export default NotFound
